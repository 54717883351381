<template>
  <div class="admin">
    <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/admin">Admin</router-link> |
    </div>
    <!--<img id="logo" alt="Vue logo" src="../assets/logo.png">-->
    <div class="mx-5">
      <Feature msg="Features in Aptitude project."/>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Feature from '@/components/Feature.vue'

export default {
  name: 'Home',
  components: {
    Feature
  }
}
</script>

<style>
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
