<template>
  <div>
    <h1>Result Images Generator</h1>
    <div class="d-flex mt-5">
      <canvas id="canvas"></canvas>
      <div class="m-4" :class="{ 'd-none': generationCompleted }">generating...</div>
      <div class="m-4" :class="{ 'd-none': !generationCompleted }">
        <button type="button" class="btn btn-primary px-4 m-3" @click="download">Download {{ downloadsCount }} pngs</button>
        <div>This will take aleast {{ Math.floor(downloadsCount / 2 / 60) }} minutes.</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Feature',
  data() {
    return {
      generationCompleted: false,
      downloadsCount: 0
    };
  },
  mounted() {
    var image1 = new Image();

    var thisComponent = this;
    image1.onload = function(e) {
      console.log('image1 loaded');
      generateDownloads(e.target);
      thisComponent.generationCompleted = true;
      thisComponent.downloadsCount = document.querySelectorAll('.downloadImage').length;
    };

    image1.src = '/images/result0x0.svg';
  },
  methods: {
    download() {
      document.querySelectorAll('.downloadImage').forEach(function(el, index) {
        setTimeout(function() {
          el.click();
          document.body.removeChild(el);
        }, index*500);
      });
    }
  }
}

function generateDownloads(bgImage) {
  for (let x=10; x<=40; x++) {
    for (let y=10; y<=40; y++) {
      let base64Img = createImage(bgImage, [x, y]);
      let a1 = document.createElement('a');
      a1.href = base64Img; //make the link of image
      a1.className = 'downloadImage';
      a1.download = "result" + x + "x" + y + "@2x.png";
      document.body.appendChild(a1);
    }
  }
}

function createImage(bgImage, score) {
  // export configs
  const exportSize = 2;
  const fontSize = 16 * exportSize;
  let pointMin = { x: 3.5 * fontSize, y: 3.5 * fontSize },
      pointMax = { x: 26 * fontSize, y: 26 * fontSize },
      scoreMin = { x: 10, y: 10 },
      scoreMax = { x: 40, y: 40 };

  //let canvas = document.createElement('canvas');
  let canvas = document.getElementById('canvas');
  canvas.width = bgImage.width * exportSize;
  canvas.height = bgImage.height * exportSize;
  let context = canvas.getContext('2d');

  // draw bgImage
  context.drawImage(bgImage, 0, 0, canvas.width, canvas.height);

  // calculate point cords
  let ratioX = (pointMax.x - pointMin.x) / (scoreMax.x - scoreMin.x),
      ratioY = (pointMax.y - pointMin.y) / (scoreMax.y - scoreMin.y);
  let origin = { x: pointMin.x + (pointMax.x - pointMin.x) / 2, y: pointMin.y + (pointMax.y - pointMin.y) / 2 },
      result = {
        x: (score[0] - scoreMin.x) * ratioX + pointMin.x,
        y: (score[1] - scoreMin.y) * ratioY + pointMin.y
      };

  // calculate offset of numbers
  let offset = { x: 0, y: 0 };
  if ((result.x + fontSize * 4) > pointMax.x) { offset.x = -(3.375 * fontSize); }
  else { offset.x = -(0.625 * fontSize); }
  if (result.y <= origin.y) { offset.y = -(1 * fontSize); }
  if (result.y > origin.y) { offset.y = 2.75 * fontSize; }

  // draw score numbers background
  let bgW = 4 * fontSize,
      bgH = 1.75 * fontSize;
  let bgX = result.x + offset.x,
      bgY = result.y + offset.y - bgH;
  context.fillStyle = "rgba(255, 182, 40, 1)";
  roundRect(context, bgX, bgY, bgW, bgH, bgH/1.7, true, false);

  // draw score numbers
  var isFirefox = typeof InstallTrigger !== 'undefined';
  if ( isFirefox ) context.font = "400 " + fontSize + "px 'sans-serif'";
  else context.font = "400 " + fontSize + "px jf-openhuninn, Avenir, Helvetica, Arial, sans-serif";
  context.fillStyle = "#fff";
  context.textAlign = "center";
  context.textBaseline = "middle";
  context.fillText( score, bgX + bgW / 2, bgY + bgH / 2);

  // draw result rect
  let rectX = Math.min(origin.x, result.x),
      rectY = Math.min(origin.y, result.y),
      rectW = Math.max(origin.x, result.x) - Math.min(origin.x, result.x),
      rectH = Math.max(origin.y, result.y) - Math.min(origin.y, result.y);

  context.fillStyle = "rgba(255, 182, 40, .4)";
  context.fillRect(rectX, rectY, rectW, rectH);

  // draw result point
  let pointW = 1.25 * fontSize,
      pointH = 1.25 * fontSize;
  let pointX = result.x - pointW / 2,
      pointY = result.y - pointH / 2;
  context.fillStyle = "rgba(255, 182, 40, 1)";
  roundRect(context, pointX, pointY, pointW, pointH, pointH/1.7, true, false);

  return canvas.toDataURL('image/png');
}

/**
 * Draws a rounded rectangle using the current state of the canvas.
 * If you omit the last three params, it will draw a rectangle
 * outline with a 5 pixel border radius
 * @param {CanvasRenderingContext2D} ctx
 * @param {Number} x The top left x coordinate
 * @param {Number} y The top left y coordinate
 * @param {Number} width The width of the rectangle
 * @param {Number} height The height of the rectangle
 * @param {Number} [radius = 5] The corner radius; It can also be an object
 *                 to specify different radii for corners
 * @param {Number} [radius.tl = 0] Top left
 * @param {Number} [radius.tr = 0] Top right
 * @param {Number} [radius.br = 0] Bottom right
 * @param {Number} [radius.bl = 0] Bottom left
 * @param {Boolean} [fill = false] Whether to fill the rectangle.
 * @param {Boolean} [stroke = true] Whether to stroke the rectangle.
 */
function roundRect(ctx, x, y, width, height, radius, fill, stroke) {
  if (typeof stroke === 'undefined') {
    stroke = true;
  }
  if (typeof radius === 'undefined') {
    radius = 5;
  }
  if (typeof radius === 'number') {
    radius = {tl: radius, tr: radius, br: radius, bl: radius};
  } else {
    var defaultRadius = {tl: 0, tr: 0, br: 0, bl: 0};
    for (var side in defaultRadius) {
      radius[side] = radius[side] || defaultRadius[side];
    }
  }
  ctx.beginPath();
  ctx.moveTo(x + radius.tl, y);
  ctx.lineTo(x + width - radius.tr, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
  ctx.lineTo(x + width, y + height - radius.br);
  ctx.quadraticCurveTo(x + width, y + height, x + width - radius.br, y + height);
  ctx.lineTo(x + radius.bl, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
  ctx.lineTo(x, y + radius.tl);
  ctx.quadraticCurveTo(x, y, x + radius.tl, y);
  ctx.closePath();
  if (fill) {
    ctx.fill();
  }
  if (stroke) {
    ctx.stroke();
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
canvas {
  border: 2px solid gray;
}
</style>
